/* google font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
  --muted: #becddb;
  --maestroOrange: #fac3ac;
  --maestroDarkOrange: #fc7760;
  --Orange-light: #fac3ac;
  --OrangeLightBkg: rgb(247, 217, 196);
  --PinkLight: rgb(242, 198, 222);
  --greenLight: rgb(201, 228, 222);
  --PurpleBkg: rgb(219, 205, 240);
  --BlueLightBkg: rgb(198, 222, 241);
  --maestroGreen: #9ae1d0;
  --niceGreen: rgb(31,157,149);
  --niceGreenTransparent: rgba(31,157,149,0.4);
  --maestroBlue: #186f8b;
  --maestroBlueLight: #eff3ff;
  --aliceBlueEnhanced: rgba(227, 230, 241, 0.7);
  --blueBkgDark:rgb(70,123,240);
  --blueBkgLight: rgb(95,145,240);
  --maestroSummer: #ff4e50;
  --maestroSummerRgba: rgba(255,78,80,0.5);
  --maestroYellow: rgb(249,214,46);
  --maestroYellowRGBA: rgba(249,214,46,0.2);

}

header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
}
.pages {
  /*max-width: 1400px;
   padding: 0px 20px 0px 20px;*/
  margin: 0 auto;
}


/* buttons */
.mc-btn-bg{
  background-color: var(--maestroYellow)!important;
}

.stepper-item.current .mc-btn-bg {
  background-color: var(--maestroSummer)!important;
}

.stepper-wrapper{
  cursor: pointer;
}

.mc-btn-bg .stepper-number span{
  color: white!important;
}

/* homepage */
.home {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}
.workout-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}
.workout-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.workout-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.workout-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

/* new workout form */
label,
input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--error);
}

/* These are Maestro Clave Styles */

.logo-mobile {
  padding:10px;
  max-width: 130px;
  max-height: 50px;
  transition: 0.3s;
}

.nav-email {
  font-size: small;
  margin: 0 8px;
}
.nav-emailwhite {
  font-size: small;
  margin: 0 8px;
  color: white;
}

.log-out-button {
  text-transform: none !important;
}

.outline-button{
  text-transform: none !important;
  font-size: small;
  display: inline-block;
}

.log-out-buttonwhite {
  border-color: white !important;
  color: white !important;
  text-transform: none !important;
}

.log-in-buttonwhite {
  color: white !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.log-in-button {
  color: white !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.nav-container {
  flex: row;
  justify-content: end;
}

.categories-wrap{
  padding: 20px;
}
.category-box-item div {
  min-height: 100px;
  min-width: 0;
  display: block;
  position: relative;
}
.category-button{
  position:absolute;
  bottom: 5px;
  right: 5px;
}
.category-card{
 background-size: cover;
 background-color: rgba(0,0,0,0.2);
 background-blend-mode: multiply;
}

.category-icon {
  padding-top: 15px;
  padding-left: 10px;
  display: block;
  position: absolute;
  top: 12px;
  left: -6px;
  background-color: rgba(0,0,0,0.3);
  color: white;
}

.category-box-item div h5 {
  text-align: right;
  color: white;
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 5px;
  display: block;
  position: absolute;
  top: 0px;
  right: 5px;
  font-size:x-small;
}

.category-box-item:first-child {
  grid-row: 1 / 4;
}

.category-box-item:first-child div {
  min-height: 210px;
  /*width: max-content;
  min-width: 29vw;*/
}

.main-categories {
  display: grid !important;
  grid-template-columns: auto auto auto;
  gap: 10px;
  width: 100%;
  position: relative;
}
.home-provider-title {
  background-size: cover;
  background-color: rgba(0,0,0,0.5);
  background-blend-mode: multiply;
  background-image: url('../public/images/home_designer.jpg');
  width: calc(100%);
  padding: 0 20px 20px 20px;
  border-radius: 0 0 13px 13px;
  background-position: center center;
}
.home-client-title {
  background-size: cover;
  background-color: rgba(0,0,0,0.5);
  background-blend-mode: multiply;
  background-image: url('../public/images/prestadores-servicio-argentina.jpg');
  background-image: url('../public/images/home_design.webp');
  width: calc(100%);
  padding: 0 20px 20px 20px;
  border-radius: 0 0 13px 13px;
  background-position: center top;
}

.inner-pages-title {
  background-size: cover;
  background-color: rgba(0,0,0,0.5);
  background-blend-mode: multiply;
  background-image: url('../public/images/home_designs.webp');
  width: 100%;
  padding: 0 20px 0px 20px;
  border-radius: 0 0 13px 13px;
  min-height: 350px;
  background-position: center center;
}

.footer-cta{
  padding: 20px 20px 200px 20px
}

.home-title-vertical-wrap {
  position: relative;
  min-height: 370px;
  margin-bottom: 100px;
}

.inner-title-vertical-wrap {
  position: relative;
  min-height: 300px;
}

.inner-title-icon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 180px;
}

.home-title-vertical {
  position: absolute;
  left: -40px;
  top: -134px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 90px;
  font-weight: bolder;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.sign-up-title-vertical{
  position: absolute;
  left: -20px;
  top: -124px;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.3);
  font-size: 60px;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}
.sign-up-title-vertical-two{
  position: absolute;
  left: 18px;
  top: -80px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 110px;
  font-weight: bolder;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}
.home-title-vertical-two {
  position: absolute;
  left: 18px;
  top: -80px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 120px;
  font-weight: bolder;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}
.country-flag {
  position: absolute;
  left: 50%;
  top: 30px;
  max-width: 51vw;
  max-height: 40vh;
}
.country-flag img {
  max-width: 100%;
  height: auto;
  max-height: 270px;;
}
.home-header {
  position: relative;
}

.home-header.banner {
  padding-bottom: 100px;
}

.home-header h1 {
  font-size: 20px;
  color: white;
  padding-right: 30px;
}

.home-header.colored h1 {
  color: var(--maestroBlue);
}

.home-form {
  width: 100%;
  padding: 15px;
  margin: 0 auto;
}

.mt--100{
  margin-top: -100px;
}

.mh-90vh{
  min-height:90vh;
}

.mh-75vh{
  min-height:75vh;
}
.mh-100vh{
  min-height:100vh;
}

.search-wrap {
  width: 100%;
  background-color: white;
  padding: 30px 15px;
  border-radius: 13px;
  box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.1);
}

.how-it-works {
  margin-top: 20px;
  padding: 30px 20px;
  background: linear-gradient( 180deg, var(--maestroBlue) 10%, var(--maestroGreen) 100%);
  width: 100%;
  border-radius: 13px ;
}

.how-it-works-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.card-list-item {
  position: relative;
  height: 92px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 13px;
  /* background: rgba(255,255,255,0.5);
background: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.1) 100%);*/
}

.card-list-item span {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: xxx-large;
  color: rgba(0, 0, 0, 0.3);
}
.card-list-item h5 {
  position: absolute;
  margin-top: 0px;
  top: 15px;
  left: 80px;
  font-weight: 500;
}

.card-list-item p {
  position: absolute;
  top: 37px;
  left: 80px;
}

.maestro-orange-button {
  background-color: var(--maestroOrange);
  color: white !important;
  text-transform: none !important;
  border-radius: 13px !important;
  height: 60px;
  font-size: 13px;
  box-shadow: none !important;
}
.maestro-black-button{
  background-color: black!important;
  color: white !important;
  text-transform: none !important;
  border-radius: 13px !important;
  height: 60px;
  font-size: 13px;
  box-shadow: none !important;
}

.button-maestro-outlined {
  height: 60px;
  border-radius: 13px !important;
  text-transform: none !important;
  min-width: 140px !important;
}



/* MATERIAL ICONS*/
/* Recommended icon sizes */
span.size-20 {
  font-size: 20px;
  font-variation-settings: "OPSZ" 20;
}
span.size-24 {
  font-size: 24px;
  font-variation-settings: "OPSZ" 24;
}
span.size-40 {
  font-size: 40px;
  font-variation-settings: "OPSZ" 40;
}
span.size-48 {
  font-size: 48px;
  font-variation-settings: "OPSZ" 48;
}

/* Remove Google Maps */
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

/* Bottom Navigation Bar */

.bottom-nav-wrap, .MuiBottomNavigation-root{
  border-radius: 20px!important;
}


.MuiBottomNavigation-root .Mui-selected span:first-child {
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: 20px;
  margin-bottom: 20px;
  transition: 0.3s linear;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: var(--maestroOrange);
  background: linear-gradient(
    148deg,
    var(--maestroSummer) 35%,
    var(--maestroYellow) 100%
  );
  border: 1px solid white;
  color: white;
}
.MuiBottomNavigationAction-label.Mui-selected {
  position: absolute;
  bottom: 8px;
}

.request-flow {
  height: 100%;
  width: 100%;
  background-color: var(--aliceBlueEnhanced);
  padding: 40px 20px;
  border-radius: 30px;
}
/* Request Answer */
.request-answer-wrap {
  position: relative;
}

.request-answer {
  width: calc(100% - 90px);
  position: relative;
  height: 88px;
  margin-bottom: 20px;
  border-radius: 13px;
  background-color: white;
  overflow: hidden;
}

.request-answer-icon {
  position: absolute;
  left: 15px;
  top: 35px;
  font-size: large;
  color: var(--maestroGreen);
}

.center-vertically {
  width: calc(100% - 70px);
  text-align: left;
  position: absolute;
  left: 45px;
  height: 88px;
}
.request-answer-label {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}

.request-answer-delete {
  position: absolute !important;
  right: -2px;
  top: 20px;
  min-width: 0px !important;
}

.confirmation-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Request Card */
.request-card{
  background-color: white;
  border-radius: 13px;
  padding: 10px 10px;
  margin-bottom: 20px;
}

.request-card-outer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.request-card-title{
  font-size: small;
  color: var(--maestroDarkOrange);
}

.stars-label{
  font-size: small;
  margin-left: 5px;
  line-height: 20px;

}

.request-card-reviews{
  font-size: small;
  line-height: 20px;

}

.request-card-review-certified span{
  line-height: 20px;
  font-size: medium;
  margin-right: 5px;
  color: var(--maestroGreen);
  
}

.request-card-review-certified {
  font-size: small;
  line-height: 20px;
  font-weight: bold;
}

.certified-label{
  text-transform: none!important;
  color: var(--maestroGreen)!important;
}

.mc-color-1{
  color: var(--maestroSummer)!important;
}
.mc-color-2{
  color: var(--maestroYellow)!important;
}
.mc-color-3{
  color: var(--niceGreenTransparent)!important;
}

.mc-bg-color-1{
  background-color: var(--maestroGreen)!important;
}
.mc-bg-color-2{
  background-color: var(--maestroBlue)!important;
}
.mc-bg-color-3{
  background-color: var(--maestroYellowRGBA)!important;
}

.contained-button{
  box-shadow: none!important;
  color: white!important;
  text-transform: none!important;

}

.btn-mc{
  background-color: var(--maestroSummer)!important;
  color: white
}
.bg-mc:hover{
  scale: 0.98;
  rotate: 2deg;
  color: white
}
.text-mc{
  color: var(--maestroSummer)!important
}

.pb-100{
  padding-bottom: 100px;
}

.pb-50{
  padding-bottom: 50px;
}

.mb-100{
  margin-bottom: 100px;
}

.mb-50{
  margin-bottom: 50px;
}
.blurry-box{
  backdrop-filter: blur(10px);
}

.border-radius{
  border-radius: calc(.625rem - 1px);
}
.user-cta{
  padding-bottom: 30px;
}
.footer{
  margin-top: -40px;
  padding: 80px 20px 150px 20px;
  background: linear-gradient( 180deg, #fff 10%, #eee 100%);
  width: 100%;
  position:relative;
  border-radius: 13px 13px 0 0;
}


.stepper.stepper-pills.stepper-column .stepper-line {
top: 50%
}
.stepper.stepper-pills.stepper-column .stepper-item:last-child .stepper-line {
display: none;
}

/* Simple responsive design */
.service-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.service-category {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .service-form {
    width: 90%;
  }
}

.close-modal{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}

/* EditServiceModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  backdrop-filter: blur(5px); /* Blur effect for background */
  z-index: 1000; /* Ensure it's above other content but below the modal */
  transition: 1s;
}

.modal {
  position: fixed;
  z-index: 1001; /* Ensure the modal is above the overlay */
  /* Keep other styles unchanged */
  transition: 1s;
}

.card-list-wrapper{
  margin-top: -30px;
  padding: 0 20px 50px 20px;
  min-height:80vh;
}


/* text with horizontal line */
p.hr{
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #d3d3d3; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
} 

p.hr span { 
   background:#fff; 
   padding:0 10px; 
}

.outer-wrap{
  display: flex;
  justify-content: center;
  min-height: 100vh;
}
.inner-wrap{
  display: flex;
  justify-content: center;
}


@media only screen and (min-width: 700px) {
  .boxed{
    width: calc(80% - 100px);
    max-width: 900px;
  }
}
@media only screen and (max-width: 699px) {
  .boxed{
    width: 100vw;
  }
}

.form-check-input:checked {
  background-color: var(--maestroYellow)!important;
  border-color: var(--maestroYellow)!important;;
}

.nav-line-tabs .nav-item .nav-link.active{
  border-bottom: 2px solid var(--maestroSummer)!important;
}

.nav-line-tabs .nav-item .nav-link:hover:not(.disabled){
  border-bottom: 2px solid var(--maestroSummerRgba)!important;
}

.MuiBottomNavigation-root, .bottom-nav-wrap{
  z-index: 100;
}